<template>
	<div :class="`programs-view program ${cfg.category}`">
		<SeoComponent
			:seoTitle="
				`${
					program.title ? program.title + ' | ' + this.cfg.nameCategory : 'IME'
				}`
			"
			:seoDescription="
				`${
					program.summary
						? program.summary
						: 'Instituto de los Mexicanos en el Exterior'
				}`
			"
			:seoTags="`${program.keywords ? program.keywords : 'gobmx,sre,ime'}`"
			:seoCanonical="
				`${
					program.canonical
						? this.cfg.category + '/programa/' + program.canonical
						: '/'
				}`
			"
			:seoImage="
				`${
					program.image_home_local
						? path.storage_files + program.image_home_local.url
						: '/images/categorias/vaipoime.jpg'
				}`
			"
		/>

		<section v-if="program">
			<div>
				<div
					class="header-page z-depth-5"
					:style="
						`background-image: url(${
							program.cover_image_local
								? path.storage_files + program.cover_image_local.url
								: program.cover_image
						})`
					"
				>
					<div class="gradiente-page">
						<b-container class="">
							<h1
								class="display-3 white-text text-center text-shadow super-strong"
							>
								{{ program.title }}
							</h1>
						</b-container>
					</div>
				</div>

				<b-container class="mt-5 pt-4">
					<b-row>
						<b-col cols="8">
							<div class="data-text">
								<b-button variant="danger" class="rojo light text-uppercase">
									<i class="fas fa-tag"></i>&nbsp; {{ program.category.name }}
								</b-button>
								&nbsp;
								<b-button variant="secondary" class="light text-uppercase">
									<i class="fas fa-calendar-alt"></i>&nbsp;
									{{ program.published_at | moment("DD/MMM/YYYY h:mm a") }}
								</b-button>
								&nbsp;

								<b-button
									variant="primary"
									v-b-tooltip.hover
									title="Comparte en Facebook"
									:href="
										`https://www.facebook.com/sharer.php?u=http://ime.gob.mx/${cfg.category}/programa/${program.canonical}&t=${program.title}`
									"
									target="_blank"
								>
									<i class="fab fa-facebook"></i>
								</b-button>
								&nbsp;
								<b-button
									variant="info"
									v-b-tooltip.hover
									title="Comparte en Twitter"
									:href="
										`https://twitter.com/?status=http://ime.gob.mx/${cfg.category}/programa/${program.canonical}`
									"
									target="_blank"
								>
									<i class="fab fa-twitter"></i>
								</b-button>
								&nbsp;
								<b-button
									v-b-tooltip.hover
									title="Comparte en Whatsapp"
									class="green accent-4 border-wa"
									:href="
										`https://api.whatsapp.com/send?text=http://ime.gob.mx/${cfg.category}/programa/${program.canonical}`
									"
									target="_blank"
								>
									<i class="fab fa-whatsapp"></i>
								</b-button>
							</div>
							<hr class="line" />

							<blockquote class="light p-4 shadow-sm grey lighten-4">
								{{ program.summary }}
							</blockquote>

							<section class="markdown-content mb-5">
								<vue-simple-markdown
									:source="`${program.paragraph}`"
								></vue-simple-markdown>
							</section>

							<section v-if="program.timeline" class="mt-5 mb-5">
								<TimelineCentral
									:cfgPostId="program.timeline.id"
								></TimelineCentral>
							</section>

							<section v-if="program.multimedia" class="video-gallery">
								<CarouselVideo
									v-if="program.multimedia.position_videos === 'body'"
									:cfgTitle="program.multimedia.title_videos"
									:cfgArrayGallery="program.multimedia.videos"
									:cfgCategory="cfg.category"
								/>
							</section>

							<div v-if="program.multimedia" class="pt-5 ">
								<MasonryGallery
									v-if="program.multimedia.position_images === 'body'"
									:cfgTitle="program.multimedia.title_images"
									:cfgArrayGallery="program.multimedia.images"
									:cfgCategory="cfg.category"
								/>
							</div>

							<section
								v-if="
									program.add_accordion === true &&
										program.module_type === 'accordion'
								"
							>
								<Accordion
									:cfgTitle="program.AccordionName"
									:cfgExternalContent="program.external_content"
									:cfgArrayAccordion="program.accordion"
								></Accordion>
							</section>

							<section
								v-if="
									program.add_accordion === true &&
										program.module_type === 'cards'
								"
							>
								<CardModule
									:cfgTitle="program.AccordionName"
									:cfgExternalContent="program.external_content"
									:cfgArrayAccordion="program.accordion"
								></CardModule>
							</section>
						</b-col>

						<b-col cols="4">
							<aside class="mb-5 pb-3 component-sidebar">
								<b-img
									fluid-grow
									:src="`${path.storage_files + program.image_home_local.url}`"
									:alt="`${program.image_home_local.name}`"
									class="z-depth-2 "
								/>
								<h6 class="mt-2 text-center">{{ program.title }}</h6>
							</aside>

							<SidebarVideo
								v-if="program.multimedia.position_videos === 'sidebar'"
								cfgTitle="Videos"
								:cfgArrayGallery="program.multimedia.videos"
								:cfgCategory="cfg.category"
							/>

							<CarouselGallery
								v-if="program.multimedia.position_images === 'sidebar'"
								cfgTitle="Galeria"
								:cfgArrayGallery="program.multimedia.images"
								:cfgCategory="cfg.category"
							/>

							<aside
								v-if="program.posts.length >= 1"
								class="mb-5 pb-3 component-sidebar"
							>
								<h3><i class="fab fa-readme"></i> Artículos relacionados</h3>
								<hr class="line sm" />
								<b-row v-for="post in program.posts" :key="post.id">
									<b-col cols="4">
										<b-img
											fluid
											:src="
												`${
													post.image_home_local
														? path.storage_files +
														  post.image_home_local.formats.thumbnail.url
														: post.image_home
												}`
											"
											class="z-depth-2"
										></b-img>
									</b-col>
									<b-col cols="8">
										<h5>
											<b-link
												:to="`/${cfg.category}/articulo/${post.canonical}`"
												><!-- Ajustar para Slug -->
												{{ post.title }}
											</b-link>
										</h5>
									</b-col>
								</b-row>
							</aside>

							<aside
								v-if="program.category.social_media.twitter"
								class="side-block mb-5 pb-3"
							>
								<h3 class="">
									Twitter @{{
										program.category.social_media.twitter
											? program.category.social_media.twitter
											: "IME_SRE"
									}}
								</h3>
								<hr class="line sm" />
								<Timeline
									:id="
										`${
											program.category.social_media.twitter
												? program.category.social_media.twitter
												: 'IME_SRE'
										}`
									"
									sourceType="profile"
									:options="{ tweetLimit: '2' }"
								></Timeline>
							</aside>

							<aside v-if="program.father_program === true" class="mb-5 pb-3">
								<h3>
									<i class="fas fa-project-diagram"></i> Paginas relacionadas
								</h3>
								<hr class="line sm" />
								<pre>{{ program.father_program }}</pre>
							</aside>
						</b-col>
					</b-row>
				</b-container>
			</div>
		</section>

		<section v-else>
			<br />
			<h1 class="display-1">Error 404</h1>
		</section>
	</div>
</template>

<script>
import SeoComponent from "@/components/Global/Seo";
import Sidebar from "@/components/layout/Sidebar";
import MasonryGallery from "@/components/Custom/Programs/MasonryGallery";
import CarouselVideo from "@/components/Custom/Programs/CarouselVideo";
import CarouselGallery from "@/components/Custom/Programs/CarouselGallery";
import SidebarVideo from "@/components/Custom/Programs/SidebarVideo";
import Accordion from "@/components/Custom/Accordions/AccordionBody";
import CardModule from "@/components/Custom/Cards/CardBody";
import { Timeline } from "vue-tweet-embed";
import TimelineCentral from "@/components/Custom/Timeline/Central";

export default {
	name: "ShowPost",
	components: {
		SeoComponent,
		Sidebar,
		MasonryGallery,
		CarouselGallery,
		CarouselVideo,
		SidebarVideo,
		Accordion,
		CardModule,
		Timeline,
		TimelineCentral,
	},

	data() {
		return {
			program: [],
			path: {
				base_url: process.env.VUE_APP_BASE_URL,
				endpoint: process.env.VUE_APP_ENDPOINT,
				storage_files: process.env.VUE_APP_STORAGE_FILES,
			},
			cfg: {
				showMenu: this.$route.meta.cfgShowMenu,
				category: this.$route.meta.cfgCategory,
				nameCategory: this.$route.meta.cfgNameCategory,
			},
		};
	},

	mounted() {},

	/*
	 * Muestra resultado de campo canonical/slug
	 */
	async created() {
		await fetch(
			this.path.endpoint +
				"/programs/?_publicationState=preview&published_at_null=false&canonical=" +
				this.$route.params.canonical // <- Ajuste segun Post
		)
			.then((response) => response.json())
			.then((data) => {
				this.program = data[0];
				console.log(data);
			})
			.catch((err) => {
				console.error(err);
			});
	},
	/* End Post */

	methods: {
		onSlideStart(slide) {
			this.sliding = true;
		},
		onSlideEnd(slide) {
			this.sliding = false;
		},
	},
};
</script>
