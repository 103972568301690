<template>
  <div class="programs-category program-video">

      <aside class="mb-5 pb-3 component-sidebar">
        <h3><i class="fab fa-youtube"></i> {{cfgTitle}}</h3>
        <hr class="line sm">
        <div 
          v-for="video in cfgArrayGallery" 
          :key="video.id"
          class="lime darken-3 white-text box-10 p-3 pb-1 z-depth-3"
          :class="`youtube-${video.youtube_id}`"
        >
          <p class="lead mb-2 strong text-shadow">{{ video.title }}</p>
          <b-embed
            type="iframe"
            aspect="16by9"
            :src="`https://www.youtube-nocookie.com/embed/${video.youtube_id}?rel=0`"
            allowfullscreen
            class="video-sidebar box-10 z-depth-2"
          ></b-embed>
          <p class="text-center mt-1"> {{ video.description }}</p>
        </div>
      </aside>

  </div>
</template>

<script>

export default {
  name: 'SidebarVideo',

  props: {
    cfgTitle: String,
    cfgCategory: String,
    cfgArrayGallery: Array,
  },



  components: {},



  data() {
    return {
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },

    }
  }
 
}
</script>
