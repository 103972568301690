<template>
  <div class="programs-category program-carousel-gallery">
    <aside class="mb-5 pb-3">
      <h3><i class="fas fa-images"></i> {{cfgTitle}}</h3>
      <hr class="line sm">
      <b-carousel
        id="carousel-1"
        v-model="slide"
        :interval="5000"
        controls
        indicators
        style="text-shadow: 1px 1px 2px #333;"
        @sliding-start="onSlideStart"
        @sliding-end="onSlideEnd"
        class=" z-depth-3"
      >
        <b-carousel-slide
          v-for="image in cfgArrayGallery" :key="image.id"
          :img-src="`${ image.photo ? path.storage_files + image.photo.formats.medium.url : image.external}`"
          class=""
        ></b-carousel-slide>
      </b-carousel>
    </aside>
  </div>
</template>

<script>

export default {
  name: 'CarouselGallery',

  props: {
    cfgTitle: String,
    cfgCategory: String,
    cfgArrayGallery: Array,
  },



  components: {},



  data() {
    return {
      slide: 0,
      sliding: null,
      path: {
        base_url: process.env.VUE_APP_BASE_URL,
        endpoint: process.env.VUE_APP_ENDPOINT,
        storage_files: process.env.VUE_APP_STORAGE_FILES,
      },
      cfg:{
        showMenu: this.$route.meta.cfgShowMenu,
        category: this.$route.meta.cfgCategory,
        nameCategory: this.$route.meta.cfgNameCategory,
      },

    }
  },


  methods: {
    onSlideStart(slide) {
      this.sliding = true
    },
    onSlideEnd(slide) {
      this.sliding = false
    }
  }
 
}
</script>
